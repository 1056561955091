import React from 'react'

import Banner from '../components/banner'
import Layout from '../components/layout'

export default function Diseases(): JSX.Element {
  return (
    <Layout>
      <div id="basics">
        <Banner bannerImage="Diseases" bannerText="Genetic Diseases" textColor="black" />

        <div id="articleContainer">
          <h5>
            Everyone has a different image in their head when they hear the word
            &apos;disease.&apos; For some, it means being sick and vomiting up chocolate milk all
            over the bus, and for others, it may be symbolized by the thought of the dreaded chicken
            soup &apos;cure.&apos; Alternatively, some may even think about the classic pretending
            to be sick, so you get to stay home from school trick. However, some diseases or
            sicknesses are much much more severe and can be long-lasting. Below, we will discuss a
            few of these. If you or a loved one has faced any of these, the process can be
            overwhelming and challenging, but you are not alone. These disorders or diseases impact
            millions of people worldwide, including your favorite Hollywood stars. Trust me; even
            famous people have diarrhea now and then. However, all joking aside, some disorders
            occur due to genetically inherited traits (things passed on from our parents). Here, we
            are specifically going to talk about some common genetically inherited diseases.
          </h5>

          <div className="articleGrid">
            <div>
              <h4>Cardiomyopathy</h4>
            </div>
            <div>
              <h4>Hereditary Breast and Ovarian Cancer (HBOC)</h4>
            </div>
            <div>
              <h4>Arrhythmia</h4>
            </div>
            <div>
              <h4>Lynch Syndrome</h4>
            </div>
            <div>
              <h4>Inherited High Cholesterol</h4>
            </div>
            <div>
              <h4>Rare Diseases</h4>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
